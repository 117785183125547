<template>
    <div class="main">
        <MenuApp></MenuApp>
        <div class="code-generate">
            <div class="code-btn" @click="generateCode()">Generate a code</div>
        </div>
        <div class="code-table">
            <table>
                <thead>
                    <tr>
                        <th>Code</th>
                        <th>Activation status</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, idx) in codesList" :key="idx">
                        <td> {{ item.gt_code}}</td>
                        <td>
                            <label class="switch">
                                <input type="checkbox" :checked="item.gt_state === 1" @click="changeState(item.gt_id, item.gt_state)">
                                <span class="slider round"></span>
                            </label>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import MenuApp from './../components/MenuApp'
import axios from 'axios'
import store from './../store'

export default {
    name : "Codes",
    components : {
        MenuApp
    },
    data() {
        return {
            codesList : []
        }
    },
    methods: {
        changeState(id, state) {
            let newState = null;
            state === 1 ? newState = 2 : newState = 1

            let params = {
                id : id,
                state : newState
            }

            let config = {
                validateStatus: () => true,
            };

        
            config.headers = { Authorization: `Bearer ${store.state.JWT_TOKEN}` }
            
            let vm = this

            axios
                .put(store.getters.getURL +'codes', params, config)
                .then(function (response) {
                    if(response.status === 200) {
                        vm.codesList.find(e => e.gt_id === id).gt_state = newState
                    } else {
                        store.dispatch('addNotif', {type : 2, content : response.data.message})
                    }
                    })
                .catch((res) => {
                   store.dispatch('addNotif', {type : 2, content : res})
                });


        },
        generateCode() {
            let config = {
                validateStatus: () => true,
            };

        
            config.headers = { Authorization: `Bearer ${store.state.JWT_TOKEN}` }
            
            let vm = this

            let params = {
                action : 'generate'
            }

            axios
                .post(store.getters.getURL +'codes', params, config)
                .then(function (response) {
                    if(response.status === 200) {
                        vm.codesList.push(response.data.newCode)
                    } else {
                        store.dispatch('addNotif', {type : 2, content : response.data.message})
                    }
                    })
                .catch((res) => {
                    store.dispatch('addNotif', {type : 2, content : res})
                });
        },
    },
    mounted(){
        let config = {
            validateStatus: () => true,
        };

        
        config.headers = { Authorization: `Bearer ${store.state.JWT_TOKEN}` }
        
        let vm = this

        axios
            .get(store.getters.getURL +'codes', config)
            .then(function (response) {
                if(response.status === 200) {
                    vm.codesList = response.data.codes
                } else {
                    store.dispatch('addNotif', {type : 2, content : response.data.message})
                }
                })
            .catch((res) => {
                store.dispatch('addNotif', {type : 2, content : res})
            });
    }
}
</script>

<style  scoped>
    .code-generate {
        display:flex;
        justify-content: center;
        margin:40px auto;
    }

    .code-btn {
        padding:5px 25px;
        border-radius:10px;
        background:#335c68;
        color:#fff;
        font-size:20px;
        font-family: 'Arimo';
        cursor: pointer;
        transition:0.5s;
    }

    .code-btn:hover {
        background: #ef7941;
    }

    .code-table {
        width:600px;
        max-width: 90%;
        margin:0 auto;
        border:1px solid #a4a5a6;
        border-radius:10px;
    }

    .switch {
        position: relative;
        display: inline-block;
        width: 35px;
        height: 14px;
    }

    .switch input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    /* The slider */
    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #e6e6e6;
        -webkit-transition: .4s;
        transition: .4s;
    }

    .slider:before {
        position: absolute;
        content: "";
        height: 20px;
        width: 20px;
        left: -5px;
        bottom: -3px;
        background-color: #d3d1d1;
        -webkit-transition: .4s;
        transition: .4s;
    }

    input:checked + .slider:before {
        background-color: #ef7941;
    }

    input:focus + .slider {
        box-shadow: 0 0 1px #ef7941;
    }

    input:checked + .slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
    }

    /* Rounded sliders */
    .slider.round {
        border-radius: 24px;
    }

    .slider.round:before {
        border-radius: 50%;
    }
</style>